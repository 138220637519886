import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import sr from '@utils/sr';
import { srConfig } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  flex-direction: column;
  align-items: flex-start;
`;
const StyledContent = styled.div`
  position: relative;
  grid-column: 1 / 7;
  grid-row: 1 / -1;
  ${media.thone`
    grid-column: 1 / -1;
    padding: 40px 40px 30px;
    z-index: 5;
  `};
  ${media.phablet`padding: 30px 25px 20px;`};
`;
const StyledProjectName = styled.h5`
  font-size: 28px;
  margin: 0 0 20px;
  font-family: ${fonts.Brown};
  color: ${colors.lightestSlate};
  ${media.tablet`font-size: 24px;`};
  ${media.thone`color: ${colors.lightNavy};`};
  a {
    ${media.tablet`display: block;`};
  }
`;
const StyledDescription = styled.div`
  position: relative;
  z-index: 2;
  color: ${colors.lightNavy};
  font-family: ${fonts.Brown};
  font-size: ${fontSizes.lg};
  border-radius: ${theme.borderRadius};
  ${media.thone`
    background-color: transparent;
    padding: 20px 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `};
  p {
    margin: 0;
  }
  a {
    ${mixins.inlineLink};
  }
`;
const StyledTechList = styled.ul`
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 25px 0 10px;
  list-style: none;

  li {
    font-family: ${fonts.Brown};
    font-size: ${fontSizes.smish};
    color: ${colors.slate};
    margin-right: ${theme.margin};
    margin-bottom: 7px;
    white-space: nowrap;
    &:last-of-type {
      margin-right: 0;
    }
    ${media.thone`
      color: ${colors.lightestSlate};
      margin-right: 10px;
    `};
  }
`;
const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-left: -10px;
  color: ${colors.lightestSlate};
  a {
    padding: 10px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
const StyledIconImage = styled(Img)`
  width: 20%;
  max-width: 20%;
  margin-bottom: 32px;
  align-self: 'flex-end';
  border-radius: 16px;
  ${mixins.boxShadow};
`;
const StyledIconSvgImage = {
  width: '20%',
  maxWidth: '20%',
  marginBottom: '32px',
  alignSelf: 'flex-end',
  borderRadius: '16px',
  boxShadow: '0 10px 30px -9px #0a192f',
};
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  font-family: ${fonts.Brown};
  position: relative;
`;
const StyledImgContainer = styled.div`
  width: 80%;
  max-width: 80%;
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  position: relative;
`;
const StyledProject = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 100px;
  ${media.thone`
    margin-bottom: 70px;
  `};
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(odd) {
    ${StyledContent} {
      grid-column: 7 / -1;
      ${media.desktop`
        margin-left: 32px;
      `};
      ${media.bigDesktop`
        margin-left: 32px;
      `};
      ${media.thone`
        grid-column: 1 / -1;
        margin-left: 0px;
        width: 100%;
        padding: 40px 40px 30px;
      `};
      ${media.phablet`padding: 30px 25px 20px;`};
    }
    ${StyledTechList} {
      justify-content: flex-start;
      li {
        margin-right: 16;
      }
    }
    ${StyledLinkWrapper} {
      justify-content: flex-start;
      margin-left: 0;
      margin-right: -10px;
    }
    ${StyledImgContainer} {
      grid-column: 1 / 8;
      ${media.desktop`
        transform: scale(0.75);
      `};
      ${media.tablet`
        transform: scale(0.5);
      `};
      ${media.thone`
        display: none;
      `};
    }
  }
`;

const Featured = ({ data }) => {
  const featuredProjects = data.filter(({ node }) => node);

  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <StyledContainer id="projects">
      <div>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { title, tech, cover, icon } = frontmatter;

            return (
              <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                <StyledContent>
                  {!icon.childImageSharp && icon.extension === 'svg' ? (
                    <img style={StyledIconSvgImage} src={icon.publicURL} alt={title} />
                  ) : (
                    <StyledIconImage
                      fluid={icon.childImageSharp.fluid}
                      alt={title}></StyledIconImage>
                  )}
                  <StyledProjectName>{title}</StyledProjectName>
                  <StyledDescription dangerouslySetInnerHTML={{ __html: html }} />
                  {tech && (
                    <StyledTechList>
                      {tech.map((tech, i) => (
                        <li key={i}>{tech}</li>
                      ))}
                    </StyledTechList>
                  )}
                </StyledContent>

                <StyledImgContainer>
                  {!cover.childImageSharp && cover.extension === 'svg' ? (
                    <img src={cover.publicURL} alt={title} />
                  ) : (
                    <StyledFeaturedImg fluid={cover.childImageSharp.fluid} alt={title} />
                  )}
                </StyledImgContainer>
              </StyledProject>
            );
          })}
      </div>
    </StyledContainer>
  );
};

Featured.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Featured;

import { css } from 'styled-components';

import BrownThinTTF from '@fonts/Brown/Brown-Thin.ttf';
import BrownThinWOFF from '@fonts/Brown/Brown-Thin.woff';
import BrownLightTTF from '@fonts/Brown/Brown-Light.ttf';
import BrownLightWOFF from '@fonts/Brown/Brown-Light.woff';
import BrownRegularTTF from '@fonts/Brown/Brown-Regular.ttf';
import BrownRegularWOFF from '@fonts/Brown/Brown-Regular.woff';
import BrownBoldTTF from '@fonts/Brown/Brown-Bold.ttf';
import BrownBoldWOFF from '@fonts/Brown/Brown-Bold.woff';

const FontFaces = css`
  @font-face {
    font-family: 'Brown';
    src: url(${BrownThinWOFF}) format('woff'),
      url(${BrownThinTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Brown';
    src: url(${BrownLightWOFF}) format('woff'),
      url(${BrownLightTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Brown';
    src: url(${BrownRegularWOFF}) format('woff'),
      url(${BrownRegularTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Brown';
    src: url(${BrownBoldWOFF}) format('woff'),
      url(${BrownBoldTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default FontFaces;
